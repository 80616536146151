// 處理 contentType 為 text ，該如何顯示快捷回復及常見問題的內容
export function stripHtml (value) {
  if (!value) { return '' }

  // 1. 處理 <img> 標籤: 將 <img> 標籤替換為 "(圖片)"
  value = value.replace(/<img[^>]*>/g, '(圖片)')

  // 2. 移除空的 <p></p> 標籤
  value = value.replace(/<p>\s*<\/p>/g, '') // 去除空的 <p></p> 標籤

  // 3. 用空格替換 <p> 標籤，只對有內容的 <p> 標籤做處理
  value = value.replace(/<p>/g, ' ').replace(/<\/p>/g, ' ')

  // 4. 移除其他所有 HTML 標籤
  value = value.replace(/<[^>]*>/g, '') // 去除 HTML 標籤

  // 5. 修正：去除多餘的空格，確保段落之間只有一個空格
  value = value.replace(/\s+/g, ' ').trim()

  return value
}

// 判斷 LastMessageContentType 來顯示 lastMessage 內容
export function getMessageContentBasedOnType (message, t, isConvInfo = false) {
  const contentType = isConvInfo ? message.lastMessageContentType : message.contentType
  const content = isConvInfo ? message.lastMessage : message.content

  // 如果是 'text'，直接返回內容
  if (contentType === 'text') { return stripHtml(content) }

  // 否則根據 contentType 返回翻譯內容，沒有的話返回原始內容
  const contentTypeMapping = {
    image: 'convDetail_convInfo_lastMessageContentType_image',
    audio: 'convDetail_convInfo_lastMessageContentType_audio',
    video: 'convDetail_convInfo_lastMessageContentType_video',
    file: 'convDetail_convInfo_lastMessageContentType_file'
  }

  return contentTypeMapping[contentType] ? t(contentTypeMapping[contentType]) : content
}
